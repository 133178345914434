<template>
  <c-box
    position="relative"
    z-index="10"
    :margin-top="[isAuthenticated ? '0px' :'36px', isAuthenticated ? '0px' : '32px']"
    :background="isAuthenticated ? ['linear-gradient(180deg, rgba(0, 140, 130, 1) 0%, rgba(242, 242, 242, 1) 90%)', 'linear-gradient(180deg, rgba(0, 140, 130, 1) 0%, rgba(255, 255, 255, 0.2) 80%)'] : 'unset'"
    :border-top-left-radius="isAuthenticated ? ['50px' ,'150px'] : '0px'"
  >
    <!-- Program dan Layanan Dietela -->
    <c-flex
      position="relative"
      background-color="transparent"
      w="100%"
      max-width="1100px"
      justify-content="center"
      align-items="center"
      margin-bottom="1rem"
      mx="auto"
      z-index="2"
    >
      <c-flex
        id="list-program"
        flex-direction="column"
        w="100%"
        justify-content="center"
        align-content="center"
        p="1rem"
        pos="relative"
      >
        <c-text
          :font-size="['16px', '28px']"
          font-weight="600"
          color="#000000"
          :mb="['8px', '1.5rem']"
          align="center"
          font-family="Roboto"
        >
          Program dan Layanan Dietela
        </c-text>
        <c-grid
          v-if="productCategory.length > 0"
          :w="['100%', '80%']"
          :template-columns="`repeat(${productCategory.length > 4 ? 4 : productCategory.length}, 1fr)`"
          mx="auto"
          gap="6px"
          :align-items="['flex-start', 'center']"
        >
          <c-button
            v-for="(menu, index) in productCategory"
            :key="index"
            v-chakra="{
              ':hover': {
                color: '#FFFFFF',
                backgroundColor: '#005A64',
                transitionDuration: '300ms',
              },
              '&.active, &:focus, &:active': {
                color: '#FFFFFF',
                backgroundColor: '#008C81',
                width: ['100%', '220px'],
                height: ['80px', '180px'],
                flexDirection: ['column-reverse', 'column-reverse'],
              },
            }"
            :class="{ active: menu.id == active }"
            d="flex"
            :height="['67px', '160px']"
            :width="['100%', '160px']"
            mx="auto"
            :flex-direction="['column-reverse', 'column']"
            jusity-content="center"
            align-items="center"
            border-radius="12px"
            :p="['5px', '20px']"
            :box-shadow="['0px 2px 2px 0px #008C8126', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
            cursor="pointer"
            :color="['#111111', '#008C81']"
            background-color="#FFFFFF"
            white-space="pre-warp"
            :gap="['8px', '16px']"
            @click="selectMenu(menu, index < centerCategoryProgramIndex ? 'left' : 'right')"
          >
            <c-text
              :font-size="['10px', '16px']"
              font-family="Roboto"
            >
              {{ menu.name }}
            </c-text>
            <c-image
              :src="menu.icon"
              :w="['50px', '120px']"
              :border-radius="['4px','12px']"
            />
          </c-button>
        </c-grid>
      </c-flex>
    </c-flex>

    <!-- List Product by Program -->
    <c-flex
      v-if="products"
      w="100%"
      max-width="1100px"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      :margin-top="['24px', '32px']"
      margin-bottom="16px"
      mx="auto"
      :px="['1rem', '0px']"
      gap="16px"
    >
      <SkeletonProgram
        v-show="isLoadingCard"
      />
      <CardProgram
        v-for="(product, index) in products"
        v-show="!isLoadingCard"
        :key="index"
        :data="product"
        :loading="isLoadingButton"
        :align="cardAlign"
        @on-click="openDetailProduct"
      />
    </c-flex>
    <Portal to="program-recommendation">
      <RecommendationProgram
        v-if="!isAuthenticated"
        :product-slug="firstProductSlug"
        :category-id="active"
        :product-duration="30" 
      />
    </Portal>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardProgram from '@/components/cards/card-program'
import SkeletonProgram from '@/components/skeletons/skeleton-program'
import RecommendationProgram from '@/components/program/recommendation'
import { ENV } from '@/constants/config'

export default {
  name: 'ProgramsComp',
  components: {
    CardProgram,
    SkeletonProgram,
    RecommendationProgram,
  },
  data() {
    return {
      active: '',
      isLoadingCard: false,
      isOpenModal: false,
      isLoadingButton: false,
      cardAlign: 'left',
    }
  },
  computed: {
    ...mapGetters({
      productCategory: 'general/productCategory',
      isAuthenticated: 'auth/isAuthenticated',
      products: 'clients/productsByName',
      product: 'clients/product',
    }),
    firstProductSlug() {
      return this.products.length > 0 ? this.products[0]?.slug : null
    },
    centerCategoryProgramIndex() {
      return Math.floor(this.productCategory.length / 2)
    },
  },
  async mounted() {
    await this.loadProductCategory()
    if (this.productCategory.length > 0) {
      this.selectMenu(this.productCategory[0])
    }
  },
  methods: {
    ...mapActions({
      loadProductCategory: 'general/loadProductCategory',
      listProducts: 'clients/getProductByName',
      detailProduct: 'clients/getProductById',
    }),
    async loadProducts() {
      this.isLoadingCard = true
      await this.listProducts(this.active)
      this.isLoadingCard = false
    },
    selectMenu(menu, align = 'left') {
      if (this.active != menu.id) {
        this.cardAlign = align
        this.active = menu.id
        this.loadProducts()
      }
    },
    async openDetailProduct(data) {
      if (data?.name?.toLowerCase().includes('enterprise')) {
        // this.$router.push({
        //   name: 'client.enterpriseService',
        // })
        // return

        // Redirect to extenal program page
        return window.location.href = ENV.URL_DIETELA_LANDING_PAGE + '/layanan-enterprise'
      }
      // this.$router.push({
      //   name: 'client.program.detail',
      //   params: {
      //     id: data?.slug ? data?.slug : data?.id,
      //   },
      // })
      
      // Redirect to extenal program page
      return window.location.href = ENV.URL_DIETELA_LANDING_PAGE + '/program/' + data?.slug ?? data?.id
    },
    serviceTarget(item) {
      if (!item) return ''
      return item.split('\n')
    },
  },
}
</script>
